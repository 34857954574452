<template>
  <lab-task><lab-gas-laws-part1 @allGuidanceComplete="submitResponse" /></lab-task>
</template>

<script>
import {CompletableSimMixin} from '../../mixins/completable-sim';
import {defineComponent} from '@vue/composition-api';
import LabGasLawsPart1 from '../simulations/LabGasLawsPart1';
import LabTask from './labs/LabTask';

export default defineComponent({
  name: 'GasLawsPart1',
  components: {LabGasLawsPart1, LabTask},
  mixins: [CompletableSimMixin()],
});
</script>
